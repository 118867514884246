import React from 'react'
import { Redirect, Layout, Hero, Container, Section } from 'components'
import locales from 'config/locales'
import heroImage from 'images/guidance-hero.jpg'

export const GuidancePage = ({ language }) => {
  const locale = locales[language]
  const page = locale.pages.guidance
  return (
    <Layout language={language}>
      <Hero
        backgroundImage={heroImage}
        backgroundPosition={{
          _: '56% 32%',
          sm: '86% -40%',
          md: '86% 20%',
          lg: '86%, 60%',
        }}
        backgroundSize={'113% auto'}
      />
      <Container marginBottom={8} marginTop={2}>
        <Section type="text" {...page.supportForHealing} />
        <Section type="text" {...page.alchemicalBreath} />
        <Section type="text" {...page.metatherapy} />
        <Section type="text" {...page.intuitiveReadings} />
      </Container>
    </Layout>
  )
}

export default Redirect
